import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Col, Row } from "react-bootstrap";
import axios from "axios";

const Consultationpopup = props => {
 const [formData, setFormData] = useState({
  bhk: "",
  name: "",
  email: "",
  mobile: "",
 });

 const [errors, setErrors] = useState({});
 const [isLoading, setIsLoading] = useState(false);
 const handleChange = e => {
  const { name, value } = e.target;
  setFormData(prevData => ({ ...prevData, [name]: value }));
  setErrors(prevErrors => ({ ...prevErrors, [name]: "" }));
 };

 const validateForm = () => {
  const validationErrors = {};

  if (!formData.bhk) {
   validationErrors.bhk = "BHK is required";
  }

  if (!formData.city) {
   validationErrors.city = "City is required";
  }

  if (!formData.name) {
   validationErrors.name = "Name is required";
  }

  if (!formData.email) {
   validationErrors.email = "Email is required";
  } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
   validationErrors.email = "Invalid email format";
  }

  if (!formData.mobile) {
   validationErrors.mobile = "Mobile number is required";
  } else if (!/^\d{10}$/.test(formData.mobile)) {
   validationErrors.mobile = "Invalid mobile number";
  }

//   if (!formData.whatsapp) {
//    validationErrors.whatsapp = "Consenting is required";
//   }

  return validationErrors;
 };
 const handleSubmit = async e => {
  e.preventDefault();
  const validationErrors = validateForm();
  if (Object.keys(validationErrors).length === 0) {
   setIsLoading(true);
   try {
    await axios.post("https://www.cityscapedecors.com:3001/send-email", {
     bhk: formData.bhk,
     city: formData.city,
     name: formData.name,
     email: formData.email,
     mobile: formData.mobile,
     whatsapp: formData.whatsapp,
    });
    setFormData({
     bhk: "",
     city: "",
     name: "",
     email: "",
     mobile: "",
     whatsapp: "",
    });
    setIsLoading(false);
    alert("Email sent successfully");
   } catch (error) {
    console.error(error);
    alert("An error occurred while sending the email");
   }
   setIsLoading(false);
  } else {
   setErrors(validationErrors);
  }
 };
 

 return (
  <>
   <Modal
    {...props}
    size="xl"
    aria-labelledby="get-a-free-consultation"
    centered
    className="GFConsultPop">
    <Modal.Body>
     <Button onClick={props.onHide} className="closebtn">
      X
     </Button>
     <Row>
      <Col md="4">
       <img src={require("../../asstes/images/GetPopImg.png")} alt="" />
      </Col>
      <Col md="8">
       <div className="popformsec">
        <h2>
         Get a <strong>Free Consultation</strong>
        </h2>
        <form noValidate onSubmit={handleSubmit} className="GConFrom">
         <div className="ConfrmRow">
          <div className="formcol checkboxform Ffullfcol">
           <label>Tell us about your home</label>
           <div>
            <div className="form-check form-check-inline">
             <input
              type="radio"
              id="inline-radio-1"
              className="form-check-input"
              name="bhk"
              value="1 bhk"
              checked={formData.bhk === "1 bhk"}
              onChange={handleChange}
             />
             <label htmlFor="inline-radio-1" className="form-check-label">
              1 bhk
             </label>
            </div>
            <div className="form-check form-check-inline">
             <input
              type="radio"
              id="inline-radio-2"
              className="form-check-input"
              name="bhk"
              value="2 bhk"
              checked={formData.bhk === "2 bhk"}
              onChange={handleChange}
             />
             <label htmlFor="inline-radio-2" className="form-check-label">
              2 bhk
             </label>
            </div>
            <div className="form-check form-check-inline">
             <input
              type="radio"
              id="inline-radio-3"
              className="form-check-input"
              name="bhk"
              value="3 bhk"
              checked={formData.bhk === "3 bhk"}
              onChange={handleChange}
             />
             <label htmlFor="inline-radio-3" className="form-check-label">
              3 bhk
             </label>
            </div>
            {errors.bhk && <p className="field-error">{errors.bhk}</p>}
           </div>
          </div>
          <div className="formcol checkboxform Ffullfcol">
                <label>Which city is this home in?</label>
                <div>
                    <div className="form-check form-check-inline">
                    <input
                        type="radio"
                        id="inline-radio-4"
                        className="form-check-input"
                        name="city"
                        value="Chennai"
                        checked={formData.city === 'Chennai'}
                        onChange={handleChange}
                    />
                    <label htmlFor="inline-radio-4" className="form-check-label">
                        Chennai
                    </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            type="radio"
                            id="inline-radio-5"
                            className="form-check-input"
                            name="city"
                            value="Coimbatore"
                            checked={formData.city === 'Coimbatore'}
                            onChange={handleChange}
                        />
                        <label htmlFor="inline-radio-5" className="form-check-label">
                            Coimbatore
                        </label>
                        </div>
                        <div className="form-check form-check-inline">
                        <input
                            type="radio"
                            id="inline-radio-6"
                            className="form-check-input"
                            name="city"
                            value="Bangalore"
                            checked={formData.city === 'Bangalore'}
                            onChange={handleChange}
                        />
                        <label htmlFor="inline-radio-6" className="form-check-label">
                            Bangalore
                        </label>
                        </div>
                        <div className="form-check form-check-inline">
                        <input
                            type="radio"
                            id="inline-radio-7"
                            className="form-check-input"
                            name="city"
                            value="Mumbai"
                            checked={formData.city === 'Mumbai'}
                            onChange={handleChange}
                        />
                        <label htmlFor="inline-radio-7" className="form-check-label">
                            Mumbai
                        </label>
                        </div>
                        <div className="form-check form-check-inline">
                        <input
                            type="radio"
                            id="inline-radio-8"
                            className="form-check-input"
                            name="city"
                            value="Hyderabad"
                            checked={formData.city === 'Hyderabad'}
                            onChange={handleChange}
                        />
                        <label htmlFor="inline-radio-8" className="form-check-label">
                            Hyderabad
                        </label>
                        </div>
                        {errors.city && <p className="field-error">{errors.city}</p>}
                </div>
                </div>
          <div className="formcol Fhalfcol">
           <label>Your Name</label>
           <input
            name="name"
            placeholder="Name"
            required
            type="text"
            className="form-control"
            value={formData.name}
            onChange={handleChange}
           />
           {errors.mobile && <p className="field-error">{errors.name}</p>}
          </div>
          <div className="formcol Fhalfcol">
           <label>Your Email</label>
           <input
            name="email"
            placeholder="Email"
            required
            type="email"
            className="form-control"
            value={formData.email}
            onChange={handleChange}
           />
           {errors.mobile && <p className="field-error">{errors.email}</p>}
          </div>
          <div className="formcol Ffullfcol">
           <label>
            Tell us your mobile number, so we can fix your appointment
           </label>
           <input
            name="mobile"
            placeholder="Mobile Number"
            required
            type="tel"
            className="form-control"
            value={formData.mobile}
            onChange={handleChange}
           />
           {errors.mobile && <p className="field-error">{errors.mobile}</p>}
          </div>
          <div className="formcol whtsformcol Ffullfcol">
                <div>
                    <div className="form-check form-check-inline">
                    <input
                        type="checkbox"
                        
                        id="inline-checkbox-9"
                        className="form-check-input"
                        name="whatsapp"
                        checked={formData.whatsapp}
                        onChange={handleChange}
                    />
                    <label htmlFor="inline-checkbox-9" className="form-check-label">
                        Send me updates on Whatsapp
                    </label>
                    </div>
                    {errors.whatsapp && <p className="field-error">{errors.whatsapp}</p>}
                </div>
                </div>
          <div className="formcol Ffullfcol LastCol">
           <button className="btn btn-primary" type="submit" disabled={isLoading}>
            {isLoading ? "Submitting..." : "Submit"}
           </button>
           <p>
            Upon submitting I agree and authorize team to contact me. This will
            override the registry with DNC / NDNC
           </p>
          </div>
         </div>
        </form>
       </div>
      </Col>
     </Row>
    </Modal.Body>
   </Modal>
  </>
 );
};

export default Consultationpopup;
