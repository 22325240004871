import React from "react";
import { Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './asstes/css/style.css'
import Header from "./Components/Header/Header";
import Home from "./Pages/Home";
import Footer from "./Components/Footer/Footer";
import ScrollToTop from "./Pages/ScrollToTOp";
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init({
  disable: 'mobile',
});

function App() {
  return (
    <div className="App">
        <ScrollToTop/>
        <Header/>
        <Routes basename="/" forceRefresh={true}>
          <Route exact="true" index path={process.env.PUBLIC_URL + '/'} element={<Home />}></Route>
        </Routes>
        <Footer/>
    </div>
  );
}

export default App;

