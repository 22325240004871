import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube , faFacebookF, faInstagram, faXTwitter } from '@fortawesome/free-brands-svg-icons'
import './footer.css';
import Consultationpopup from '../../Pages/HComponents/Consultationpopup';

const Footer = () => {

  const [modalShow, setModalShow] = React.useState(false);


  return (
    <>
      <footer>
        <Container>
          <Row>
            <Col md="3">
              <address>
                <h4>Address</h4>
                <p>59, 2nd floor, 100ft Road,<br/> 1st Avenue, Ashok Nagar,<br/> Chennai 600083.</p>
              </address>
            </Col>
            <Col md="6">
              <div className='epsmdiv'>
                 <div className="epsmD EMailD"> 
                    <h4>Email</h4>
                    <Link to="mailto:cityscapedecors@gmail.com ">cityscapedecors@gmail.com </Link>
                 </div>
                 <div className="epsmD PhoneD"> 
                    <h4>Phone</h4>
                    <Link to="tel:+919677044222">+91 96770 44222</Link>
                 </div>
                 <div className="epsmD smediaD"> 
                    <h4>Keep in touch</h4>
                    <ul>
                      <li><Link to="https://instagram.com/cityscapedecorsindia?igshid=NzZhOTFlYzFmZQ==" target="_blank"><FontAwesomeIcon icon={faInstagram} /></Link></li>
                      <li><Link to="https://www.facebook.com/Cityscapedecors?mibextid=ZbWKwL" target="_blank"><FontAwesomeIcon icon={faFacebookF} /></Link></li>
                      <li><Link to="https://twitter.com/CityscapeDecors?t=r_0L9HWwJn_Jb5H5CmvmCQ&s=08" target="_blank"><FontAwesomeIcon icon={faXTwitter} /></Link></li>
                      <li><Link to="https://www.youtube.com/@CityscapedecorsChennai" target="_blank"><FontAwesomeIcon icon={faYoutube} /></Link></li>
                    </ul>                    
                 </div>
              </div>
            </Col>
            <Col md="3">
              <div className='copydiv'>
                  <p>Copyright © 2023 Cityscapedecors<br/> All Rights Reserved</p>
                  <p className='ddopen'><Link to="/" target="_blank">Design BY</Link></p>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
      <div className='scfixbtns'>
        <ul>
          <li className='whtsapp'><Link to="https://web.whatsapp.com/send?phone=919677044222?text=" target='_blank' className='WAweblink'><img src={require ('../../asstes/images/WhatsappIcon.png')} alt=""/></Link><Link to="https://api.whatsapp.com/send?phone=919677044222&text=" target='_blank' className='WAmoblink'><img src={require ('../../asstes/images/WhatsappIcon.png')} alt=""/></Link></li>
          <li className='popEnquire'><Button variant="primary" onClick={() => setModalShow(true)}>GET FREE QUOTE</Button></li>
        </ul>
      </div>
      <Consultationpopup show={modalShow} onHide={() => setModalShow(false)}/>
    </>
  )
}

export default Footer