import React, { useState } from "react";
import axios from "axios";

const BannerForm = () => {
 const [formData, setFormData] = useState({
  name: "",
  email: "",
  mobile: "",
  city: "",
 });


 const [errors, setErrors] = useState({});
 const [isLoading, setIsLoading] = useState(false);
 const handleChange = e => {
  const { name, value } = e.target;
  setFormData(prevData => ({ ...prevData, [name]: value }));
  setErrors(prevErrors => ({ ...prevErrors, [name]: "" }));
 };

 const validateForm = () => {
  const validationErrors = {};

  if (!formData.name) {
   validationErrors.name = "Name is required";
  }

  if (!formData.email) {
   validationErrors.email = "Email is required";
  } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
   validationErrors.email = "Invalid email format";
  }

  if (!formData.mobile) {
   validationErrors.mobile = "Mobile number is required";
  } else if (!/^\d{10}$/.test(formData.mobile)) {
   validationErrors.mobile = "Invalid mobile number";
  }

  if (!formData.city) {
   validationErrors.city = "City is required";
  }

  return validationErrors;
 };
 const handleSubmit = async e => {
  e.preventDefault();
  const validationErrors = validateForm();
  if (Object.keys(validationErrors).length === 0) {
   setIsLoading(true);  
   try {
    await axios.post("https://www.cityscapedecors.com:3001/send-email", {
     name: formData.name,
     email: formData.email,
     mobile: formData.mobile,
     city: formData.city,
    });
    setFormData({
     name: "",
     email: "",
     mobile: "",
     city: "",
    });
    alert("Email sent successfully");
   } catch (error) {
    console.error(error);
    alert("An error occurred while sending the email");
   }
   setIsLoading(false);
  } else {
   setErrors(validationErrors);
  }
 };

 return (
   
  <div className="BFormdiv">
   <form onSubmit={handleSubmit} className="ContusFrom">
    <div className='CotfrmRow'>
        <div className='formcol'>
          <input className="form-control" type="text" name="name" value={formData.name} onChange={handleChange} placeholder="NAME" />
          {errors.name && <p className='field-error'>{errors.name}</p>}
        </div>
        <div className='formcol'>
          <input className="form-control" type="email" name="email" value={formData.email} onChange={handleChange} placeholder="EMAIL" />
          {errors.email && <p className='field-error'>{errors.email}</p>}
        </div>
        <div className='formcol'>
          <input className="form-control" type="text" name="mobile" value={formData.mobile} onChange={handleChange} placeholder="MOBILE NUMBER" />
           {errors.mobile && <p className='field-error'>{errors.mobile}</p>}
        </div>
        <div className='formcol'>
          <input className="form-control" type="text" name="city" value={formData.city} onChange={handleChange} placeholder="YOUR CITY" />
          {errors.city && <p className='field-error'>{errors.city}</p>}
        </div>
        <div className='formcol LastCol'>
          <button className="btn btn-primary" type="submit" disabled={isLoading}>{isLoading ? 'Submitting...' : 'Submit'}</button>
        </div>
    </div>
   </form>
  </div>
 );
};

export default BannerForm;
