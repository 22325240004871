import React from 'react';
import { useEffect, useState } from 'react';
import { Form, Button, Container, Row, Col, Tabs, Tab, Accordion, Modal } from 'react-bootstrap';
import 'lightbox.js-react/dist/index.css'
import {SlideshowLightbox} from 'lightbox.js-react'
import { Link } from 'react-router-dom';
import Ourservices from './HComponents/Ourservices';
import Testimonialslide from './HComponents/Testimonialslide';
import Slider from "react-slick";
import Consultationpopup from './HComponents/Consultationpopup';
import BannerForm from './HComponents/Bannerform';
const Home = () => {
    const BpartSlide = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,        
        autoplay: true,
        autoplaySpeed: 5000,
        rows: 2,
        slidesToShow: 1, 
        slidesPerRow: 3,
        responsive: [
            {breakpoint: 550,
                settings: {
                    rows: 4
                }
            }
        ]


      };

      const YtubeSlide = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,      
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {breakpoint: 550,
                settings: {
                    slidesToShow: 2,
                }
            }
        ] 
      }

    useEffect(() => {
        document.body.classList.add('HomePage')
        return () => {
            document.body.classList.remove('HomePagex')
        }
        }, [])
    
        

        
        const [YtubeOne, setOneShow] = useState(false);
        const [YtubeTwo, setTwoShow] = useState(false);
        const [YtubeThree, setThreeShow] = useState(false);
        const [YtubeFour, setFourShow] = useState(false);
        const [YtubeFive, setFiveShow] = useState(false);
        const [YtubeSix, setSixShow] = useState(false);
        const [YtubeSeven, setSevenShow] = useState(false);
        const [YtubeEight, setEightShow] = useState(false);
        const [TwoBHKOne, setTwoBHKOneShow] = useState(false);
        const [TwoBHKTwo, setTwoBHKTwoShow] = useState(false);
        const [TwoBHKThree, setTwoBHKThreeShow] = useState(false);
        const [ThreeBHKOne, setThreeBHKOneShow] = useState(false);
        const [ThreeBHKTwo, setThreeBHKTwoShow] = useState(false);
        const [ThreeBHKThree, setThreeBHKThreeShow] = useState(false);
        
        const handleOneClose = () => setOneShow(false);
        const handleTwoClose = () => setTwoShow(false);
        const handleThreeClose = () => setThreeShow(false);
        const handleFourClose = () => setFourShow(false);
        const handleFiveClose = () => setFiveShow(false);
        const handleSixClose = () => setSixShow(false);
        const handleSevenClose = () => setSevenShow(false);
        const handleEightClose = () => setEightShow(false);
        const handleTwoBHKOneClose = () => setTwoBHKOneShow(false);
        const handleTwoBHKTwoClose = () => setTwoBHKTwoShow(false);
        const handleTwoBHKThreeClose = () => setTwoBHKThreeShow(false);
        const handleThreeBHKOneClose = () => setThreeBHKOneShow(false);
        const handleThreeBHKTwoClose = () => setThreeBHKTwoShow(false);
        const handleThreeBHKThreeClose = () => setThreeBHKThreeShow(false);

        const [modalShow, setModalShow] = React.useState(false);
        
  return (
    <>

        <section className='CDBanSec' id="BanSec">
            <div className='CDBanImg'>
                <img loading="lazy" src={require ('../asstes/images/CDHomeBannerImg.webp')} alt="Banner"/>
            
            
            </div>
            <div className='CDBanCnt'>
                <h1 data-aos="fade-down" data-aos-duration="1000">Creating Spaces That<br/> Reflect Your Personality</h1>
                <p data-aos="fade-down" data-aos-duration="1000" data-aos-delay="100">Redefines spaces with boundless creativity, innovation, and client-centered<br/> excellence, making every project an inspiring masterpiece. </p>
            </div>
            <div className='CDBanForm'>
                <BannerForm/>
                <h5>Transform Your Space, Enquire Now!</h5>
            </div>
        </section>
        <section className='CDIntroSec' id="Aboutus">
            <div className='CDIntroMImg'><img loading="lazy" src={require ('../asstes/images/CDIntroImg.jpg')} alt="About Intro"/></div>
            <Container>
                <Row>
                    <Col md="8">
                        <div className='IntroTitle ComTiles CTVer'>
                             <h2 data-aos="fade-right" data-aos-duration="1000"><span>Designing Spaces That </span><strong>Inspires and Connects</strong></h2>
                             <p data-aos="fade-right" data-aos-duration="1000" data-aos-delay="50">Cityscape Decors, a distinguished interior design company in Chennai, thrives on creativity and innovation. Our client-centered philosophy ensures surpassing design expectations. With professionalism, reliability, and timely, cost-effective project completion as our cornerstones, we fearlessly embrace challenges. Our relentless pursuit of improvement stands as a testament to our unwavering dedication to excellence, cementing our position as the go-to choice within the industry.</p>
                        </div>
                        <ul className='introlocul' data-aos="fade-right" data-aos-duration="1000" data-aos-delay="100">
                            <li>Chennai</li>
                            <li>Coimbatore</li>
                            <li>Bangalore</li>
                            <li>Mumbai</li>
                            <li>Hyderabad</li>
                        </ul>
                        <div className='CDIntroInMImg'>
                            <img loading="lazy" src={require ('../asstes/images/CDIntroImg.jpg')} alt="About Intro" className="cdiimgOne"/>
                            <img loading="lazy" src={require ('../asstes/images/CDIntroImgOne.jpg')} alt="About Intro" className="cdiimgTwo"/>
                        </div>
                        <div className='IntroAlist'>
                             <h3 data-aos="fade-right" data-aos-duration="1000" data-aos-delay="150"><small>CityScape Decors</small> Advantages</h3>
                             <ul>
                                <li data-aos="fade-right" data-aos-duration="1000" data-aos-delay="200">
                                    <h4><strong>10+</strong> Years warranty</h4>
                                    <p>Our work comes with a more than 10 years warranty, showcasing<br/> our confidence in quality craftsmanship.</p>
                                </li>
                                <li data-aos="fade-right" data-aos-duration="1000" data-aos-delay="250">
                                    <h4><strong>200+</strong> Homes Completed</h4>
                                    <p>With a track record of over 200 completed homes, we will<br/> bring your dream space to life. </p>
                                </li>
                                <li data-aos="fade-right" data-aos-duration="1000" data-aos-delay="300">
                                    <h4><strong>30</strong> Days or Pay Rent</h4>
                                    <p>Experience efficient project completion – we commit to finishing<br/> within 30 days, or we cover your rent. </p>
                                </li>
                                <li data-aos="fade-right" data-aos-duration="1000" data-aos-delay="350">
                                    <h4><strong>Design</strong> Experts</h4>
                                    <p>Our team of design pioneers curates personalized spaces that<br/> distinctly mirror your unique style.</p>
                                </li>
                             </ul>
                        </div>
                        <div className='CDIntroIBtn'>
                            <div className='CDIntroInImg'><img loading="lazy" src={require ('../asstes/images/CDIntroImgOne.jpg')} alt="About Intro"/></div>
                            <div className='CDIntrobtn'><Link to="#" onClick={() => setModalShow(true)}>Let's Talk</Link></div>
                            <Consultationpopup show={modalShow} onHide={() => setModalShow(false)}/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>        
        <section className='CDOServSec' id="OServices">
            <Container fluid>
                <div className='OSerTitle'>
                    <div className='IntroTitle ComTiles'>
                        <h2 data-aos="fade-left" data-aos-duration="1000"><span>Our </span> <strong>Services</strong></h2>
                        <p data-aos="fade-left" data-aos-duration="1000" data-aos-delay="50">Offering Comprehensive Interior Design Solutions for Homes, Offices, and Beyond.</p>
                    </div>
                </div>
                <Ourservices/>
            </Container>
        </section>
        <section className='CDRPlanSec' id="OPackages">
            <Container>
                <div className='IntroTitle ComTiles CTtextCenter CTCenter CTmarbtm'>
                    <h5 data-aos="fade-down" data-aos-duration="1000">Our Packages</h5>
                    <h2 data-aos="fade-down" data-aos-duration="1000" data-aos-delay="50"><span>Find the </span> <strong className='titleline'>Right Plan</strong></h2>
                    <p data-aos="fade-down" data-aos-duration="1000" data-aos-delay="50">Begin a journey to find your tailor-made design plans that perfectly<br/> reflect your style and needs </p>
                </div>
                <div className='PTabsDiv'>
                    <Tabs defaultActiveKey="2bhk" id="PlansTab" className="PlansTab">
                        <Tab eventKey="2bhk" title="2 BHK">
                            <div className='RPlanRow'>
                                <div className='PLCol'>
                                    <div className='PLnCntin'>
                                        <div className='PLnCHead'>
                                            <h5>2BHK</h5>
                                            <h3>BASIC</h3>
                                            <h4><span className='rupeess'>₹</span> <strong>3.5</strong> LAKHS</h4>
                                        </div>
                                        <div className='PLnCBody'>
                                            <ul>
                                                <li>Tv Unit (Suitable For 49 Inch Tv)</li>
                                                <li>Shoe Cabinet (3 Ft * 3 Ft)</li>
                                                <li>Crockery (Or) Puja Unit-1 (6 Ft * 3 Ft)</li>
                                                <li>Hinged Wardrobe – A (7 Ft * 5 Ft)</li>
                                                <li>Hinged Wardrobe – B (7 Ft * 5 Ft)</li>
                                                <li>Dress Unit - 1 (5 Ft * 2 Ft)</li>
                                                <li>King Size Cot - 1 (6 Ft * 6.5 Ft)</li>
                                            </ul>
                                            <Button  onClick={() => setModalShow(true)} className='KMLink'>Know More +</Button>
                                        </div>
                                    </div>
                                </div>
                                <div className='PLCol'>
                                    <div className='PLnCntin'>
                                        <div className='PLnCHead'>
                                            <h5>2BHK</h5>
                                            <h3>PRIME</h3>
                                            <h4><span className='rupeess'>₹</span> <strong>4.5</strong> LAKHS</h4>
                                        </div>
                                        <div className='PLnCBody'>
                                            <ul>
                                                <li>Tv Unit (Suitable For 55 Inch Tv)</li>
                                                <li>Shoe Cabinet (3 Ft * 3 Ft)</li>
                                                <li>Crockery (Or) Puja Unit-1 (6 Ft * 3 Ft)</li>
                                                <li>Hinged Wardrobe – A (7 Ft * 6 Ft)</li>
                                                <li>Hinged Wardrobe – B (7 Ft * 6 Ft)</li>
                                                <li>Dress Unit - 1 (5 Ft * 2 Ft)</li>
                                                <li>King Size Cot - 1 (6 Ft * 6.5 Ft)</li>
                                            </ul>
                                            <Button  onClick={() => setModalShow(true)} className='KMLink'>Know More +</Button>
                                        </div>
                                    </div>
                                </div>
                                <div className='PLCol'>
                                    <div className='PLnCntin'>
                                        <div className='PLnCHead'>
                                            <h5>2BHK</h5>
                                            <h3>LUXE</h3>
                                            <h4><span className='rupeess'>₹</span> <strong>5.25</strong> LAKHS</h4>
                                        </div>
                                        <div className='PLnCBody'>
                                            <ul>
                                                <li>Tv Unit (Suitable For 65 Inch Tv)</li>
                                                <li>Shoe Cabinet (3 Ft * 3 Ft)</li>
                                                <li>Crockery (Or) Puja Unit-1 (6 Ft * 3 Ft)</li>
                                                <li>Sliding Wardrobe – 1 (6 Ft * 7 Ft)</li>
                                                <li>Hinged Wardrobe – 1 (6 Ft * 7 Ft)</li>
                                                <li>Dress Unit - 1 (5 Ft * 2 Ft)</li>
                                                <li>King Size Cot - 1 (6 Ft * 6.5 Ft)</li>
                                            </ul>
                                            <Button  onClick={() => setModalShow(true)} className='KMLink'>Know More +</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="3bhk" title="3BHK">
                            <div className='RPlanRow'>
                                <div className='PLCol'>
                                    <div className='PLnCntin'>
                                        <div className='PLnCHead'>
                                            <h5>3BHK </h5>
                                            <h3>BASIC</h3>
                                            <h4><span className='rupeess'>₹</span> <strong>4.85</strong> LAKHS</h4>
                                        </div>
                                        <div className='PLnCBody'>
                                            <ul>
                                                <li>Tv Unit (Suitable For 49 Inch Tv)</li>
                                                <li>Shoe Cabinet (4 Ft * 3 Ft)</li>
                                                <li>Crockery (Or) Puja Unit-1 (6 Ft * 3.5 Ft)</li>
                                                <li>Hinged Wardrobe – 3 (7 Ft * 6 Ft)</li>
                                                <li>Dress Unit - A (5 Ft * 2 Ft)</li>
                                                <li>Dress Unit – B (5 Ft * 2 Ft)</li>
                                                <li>King Size Cot - 2 (6 Ft * 6.5 Ft)</li>
                                            </ul>
                                            <Button onClick={() => setModalShow(true)} className='KMLink'>Know More +</Button>
                                        </div>
                                    </div>
                                </div>
                                <div className='PLCol'>
                                    <div className='PLnCntin'>
                                        <div className='PLnCHead'>
                                            <h5>3BHK </h5>
                                            <h3>PRIME</h3>
                                            <h4><span className='rupeess'>₹</span> <strong>5.5</strong> LAKHS</h4>
                                        </div>
                                        <div className='PLnCBody'>
                                            <ul>
                                                <li>Tv Unit (Suitable For 55 Inch Tv)</li>
                                                <li>Shoe Cabinet (4 Ft * 3 Ft)</li>
                                                <li>Crockery (Or) Puja Unit (6 Ft * 3.5 Ft)</li>
                                                <li>Hinged Wardrobe – 3 (7 Ft * 7 Ft)</li>
                                                <li>Dress Unit - A (5 Ft * 2 Ft)</li>
                                                <li>Dress Unit – B (5 Ft * 2 Ft)</li>
                                                <li>King Size Cot - 2 (6 Ft * 6.5 Ft)</li>
                                            </ul>
                                            <Button onClick={() => setModalShow(true)} className='KMLink'>Know More +</Button>
                                        </div>
                                    </div>
                                </div>
                                <div className='PLCol'>
                                    <div className='PLnCntin'>
                                        <div className='PLnCHead'>
                                            <h5>3 BHK</h5>
                                            <h3>LUXE</h3>
                                            <h4><span className='rupeess'>₹</span> <strong>6.5</strong> LAKHS</h4>
                                        </div>
                                        <div className='PLnCBody'>
                                            <ul>
                                                <li>Tv Unit (Suitable For 65 Inch Tv)</li>
                                                <li>Shoe Cabinet (4 Ft * 3 Ft)</li>
                                                <li>Crockery (Or) Puja Unit-1 (6 Ft * 3.5 Ft)</li>
                                                <li>Sliding Wardrobe – 1 (7 Ft * 7 Ft)</li>
                                                <li>Hinged Wardrobe – 2 (7 Ft * 7 Ft)</li>
                                                <li>Dress Unit – A (5 Ft * 2 Ft)</li>
                                                <li>Dress Unit – B (5 Ft * 2 Ft)  </li>
                                            </ul>
                                            <Button onClick={() => setModalShow(true)} className='KMLink'>Know More +</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </Container>
        </section>
        <section className='CDOLProjSec' id="OLProject">
            <Container fluid>
                <div className='ComTiles CTtextCenter CTCenter CTmarbtm'>
                    <h2 data-aos="fade-down" data-aos-duration="1000"><span>Our </span> <strong>Latest <span className='titleline'>Projects</span></strong></h2>
                    <p data-aos="fade-down" data-aos-duration="1000" data-aos-delay="50">Delve into our recent portfolio, where style coalesces with functionality,<br/> shaping exceptional living spaces. </p>
                </div>
                <div className='CDOLProjSlide'>
                    <SlideshowLightbox className='marqueslide mx-auto' showThumbnails={false}>
                        <img loading="lazy" className='w-full ' src={require ('../asstes/images/OLProjImg1.webp')} alt="Latest Projects"/>
                        <img loading="lazy" className='w-full ' src={require ('../asstes/images/OLProjImg2.webp')} alt="Latest Projects"/>
                        <img loading="lazy" className='w-full ' src={require ('../asstes/images/OLProjImg3.webp')} alt="Latest Projects"/>
                        <img loading="lazy" className='w-full ' src={require ('../asstes/images/OLProjImg4.webp')} alt="Latest Projects"/>
                        <img loading="lazy" className='w-full ' src={require ('../asstes/images/OLProjImg5.webp')} alt="Latest Projects"/>
                        <img loading="lazy" className='w-full ' src={require ('../asstes/images/OLProjImg6.webp')} alt="Latest Projects"/>
                        <img loading="lazy" className='w-full ' src={require ('../asstes/images/OLProjImg7.webp')} alt="Latest Projects"/>
                        <img loading="lazy" className='w-full ' src={require ('../asstes/images/OLProjImg8.webp')} alt="Latest Projects"/>
                        <img loading="lazy" className='w-full ' src={require ('../asstes/images/OLProjImg9.webp')} alt="Latest Projects"/>
                        <img loading="lazy" className='w-full ' src={require ('../asstes/images/OLProjImg10.webp')} alt="Latest Projects"/>
                        <img loading="lazy" className='w-full ' src={require ('../asstes/images/OLProjImg1.webp')} alt="Latest Projects"/>
                        <img loading="lazy" className='w-full ' src={require ('../asstes/images/OLProjImg2.webp')} alt="Latest Projects"/>
                        <img loading="lazy" className='w-full ' src={require ('../asstes/images/OLProjImg3.webp')} alt="Latest Projects"/>
                        <img loading="lazy" className='w-full ' src={require ('../asstes/images/OLProjImg4.webp')} alt="Latest Projects"/>
                        <img loading="lazy" className='w-full ' src={require ('../asstes/images/OLProjImg5.webp')} alt="Latest Projects"/>
                        <img loading="lazy" className='w-full ' src={require ('../asstes/images/OLProjImg6.webp')} alt="Latest Projects"/>
                        <img loading="lazy" className='w-full ' src={require ('../asstes/images/OLProjImg7.webp')} alt="Latest Projects"/>
                        <img loading="lazy" className='w-full ' src={require ('../asstes/images/OLProjImg8.webp')} alt="Latest Projects"/>
                        <img loading="lazy" className='w-full ' src={require ('../asstes/images/OLProjImg9.webp')} alt="Latest Projects"/>
                        <img loading="lazy" className='w-full ' src={require ('../asstes/images/OLProjImg10.webp')} alt="Latest Projects"/>
                    </SlideshowLightbox> 
                </div>
            </Container>
        </section>
        <section className='CDBpartjSec'>
            <Container>
                <Row>
                    <Col md='4'>
                        <div className='IntroTitle ComTiles CTBlock '>
                            <h2 data-aos="fade-right" data-aos-duration="1000" data-aos-delay="50"><span>Our</span> <strong> Brand Partners</strong></h2>
                            <p data-aos="fade-right" data-aos-duration="1000" data-aos-delay="50">Collaborating with Renowned Brands to Elevate Your Space to New Heights.</p>
                        </div>
                    </Col>
                    <Col md='8'>
                        <div className='CDOBpartSlide'> 
                            <Slider {...BpartSlide}>
                                <div> <img loading="lazy" src={require ('../asstes/images/BPLogoAdvancelam.jpg')} alt=""/> </div>
                                <div> <img loading="lazy" src={require ('../asstes/images/BPLogoAristo.jpg')} alt=""/> </div>
                                <div> <img loading="lazy" src={require ('../asstes/images/BPLogoAsianpaints.jpg')} alt=""/>  </div>
                                <div> <img loading="lazy" src={require ('../asstes/images/BPLogoCenturyPly.jpg')} alt=""/> </div>
                                <div> <img loading="lazy" src={require ('../asstes/images/BPLogoCNR.jpg')} alt=""/> </div>
                                <div> <img loading="lazy" src={require ('../asstes/images/BPLogoEbco.jpg')} alt=""/> </div>
                                <div> <img loading="lazy" src={require ('../asstes/images/BPLogoEuro.jpg')} alt=""/> </div>
                                <div> <img loading="lazy" src={require ('../asstes/images/BPLogoGreenlam.jpg')} alt=""/> </div>
                                <div> <img loading="lazy" src={require ('../asstes/images/BPLogoGyproc.jpg')} alt=""/> </div>
                                <div> <img loading="lazy" src={require ('../asstes/images/BPLogoHettich.jpg')} alt=""/> </div>
                                <div> <img loading="lazy" src={require ('../asstes/images/BPLogoMerino.jpg')} alt=""/> </div>
                                <div> <img loading="lazy" src={require ('../asstes/images/BPLogoSleek.jpg')} alt=""/> </div>
                            </Slider>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        <section className='CWthUsSec'>
            <div className='CWthUsBgdiv'><img loading="lazy" src={require ('../asstes/images/CwthUsBg.jpg')} alt=""/> </div>
            <div className='CWthUstitle'>   
                <div className='ComTiles CTtextCenter CTCenter'>
                    <h2 data-aos="fade-down" data-aos-duration="1000" data-aos-delay="50"><span>Connect </span> <strong className='titleline'>with us</strong></h2>
                    <p data-aos="fade-down" data-aos-duration="1000" data-aos-delay="50">Engage with us to transform your space into an exquisite artistic masterpiece.</p>
                    <ul data-aos="fade-down" data-aos-duration="1000" data-aos-delay="50">
                        <li className="callnow"><Link to="tel:+919677044222">CALL us NOW</Link></li>
                        <li className="whatsapp"><Link to="https://web.whatsapp.com/send?phone=919677044222?text=" target='_blank' className='WAweblink'>Whatsapp</Link><Link to="https://api.whatsapp.com/send?phone=919677044222&text=" target='_blank' className='WAmoblink'>Whatsapp</Link></li>
                    </ul>
                </div>
            </div>
        </section>
        <section className='YtubeSec'>
            <Container>
                <Slider {...YtubeSlide}>
                    <div><div className='Ytubeimg'> <img loading="lazy" src={require ('../asstes/images/YTubeImg1.jpg')} alt=""  onClick={() => setOneShow(true)}/> </div></div>
                    <div><div className='Ytubeimg'> <img loading="lazy" src={require ('../asstes/images/YTubeImg2.jpg')} alt=""  onClick={() => setTwoShow(true)}/> </div></div>
                    <div><div className='Ytubeimg'> <img loading="lazy" src={require ('../asstes/images/YTubeImg3.jpg')} alt=""  onClick={() => setThreeShow(true)}/> </div></div>
                    <div><div className='Ytubeimg'> <img loading="lazy" src={require ('../asstes/images/YTubeImg4.jpg')} alt=""  onClick={() => setFourShow(true)}/> </div></div>
                    <div><div className='Ytubeimg'> <img loading="lazy" src={require ('../asstes/images/YTubeImg5.jpg')} alt=""  onClick={() => setFiveShow(true)}/> </div></div>
                    <div><div className='Ytubeimg'> <img loading="lazy" src={require ('../asstes/images/YTubeImg6.jpg')} alt=""  onClick={() => setSixShow(true)}/> </div></div>
                    <div><div className='Ytubeimg'> <img loading="lazy" src={require ('../asstes/images/YTubeImg7.jpg')} alt=""  onClick={() => setSevenShow(true)}/> </div></div>
                    <div><div className='Ytubeimg'> <img loading="lazy" src={require ('../asstes/images/YTubeImg8.jpg')} alt=""  onClick={() => setEightShow(true)}/> </div></div>
                </Slider>
            </Container>
        </section>
        <section className='TestmoSec'>
            <Container>
                <Testimonialslide />
            </Container>
        </section>
        {/*<section className='ThwithLSec displaynone' >
            <Container>
                <div className='ComTiles CTtextCenter CTCenter CTmarbtm'>
                    <h2 data-aos="fade-down" data-aos-duration="1000"><span>Transforming </span> <strong className='titleline'>homes with love</strong></h2>
                    <p data-aos="fade-down" data-aos-duration="1000" data-aos-delay="50">Pouring passion into every design, we fashion homes that harmoniously echo the desires of your heart. </p>
                </div>
                <div className='ThwithLTabs'>
                    <Tabs defaultActiveKey="ModernHome" id="transfTab" className="transfTab">
                        <Tab eventKey="ModernHome" title="Modern Home">
                            <iframe src="https://www.cityscapedecors.com/ScratchCard/" title="Scratchcard" frameBorder="0" allowFullScreen></iframe>
                        </Tab>
                        <Tab eventKey="ContemporaryHome" title="Contemporary Home"> 
                            <iframe src="https://www.cityscapedecors.com/ScratchCard/" title="Scratchcard" frameBorder="0" allowFullScreen></iframe>
                        </Tab>
                    </Tabs>
                </div>
            </Container>
        </section>*/}
        <section className='FaqsSec'>
            <Container>
                <div className='ComTiles CTtextCenter CTCenter CTmarbtm'>
                    <h2 data-aos="fade-down" data-aos-duration="1000"><strong>FAQ’S</strong></h2>
                    <p data-aos="fade-down" data-aos-duration="1000" data-aos-delay="50">Answers to your queries, assisting you in making informed Design Choices.</p>
                </div>
                <div className='faqAccordion'>
                    <Accordion defaultActiveKey="0" className="accordion ">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>What is the process to get my home interiors done by Cityscape Decors?</Accordion.Header>
                            <Accordion.Body>
                                <p>To get your complete home interior design from Cityscape Decors, there are set of stages from beginning to end. It starts with consulting stage to enable you to convey the design  requirements and style to our interior designers. Next will be documentation and design stage where our interior designers will present you the design in 3d design. Followed by production stage where the materials will be manufactured. Final stage will be On-site installation work, Quality check and Happy Handover!</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>How long is the design process?</Accordion.Header>
                            <Accordion.Body>
                                <p>Design process generally takes 7 days from the date of confirmation. However it also depends on the Customisation, Type of projects, Design evolution.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>What will be the timeline to complete entire work for my 2bhk/3bhk house?</Accordion.Header>
                            <Accordion.Body>
                                <p>At Cityscape Decors we guarantee you 30 days delivery for your 2bhk/3bhk house for Complete Modular Interiors.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>What are the advantages of using interior packages from Cityscape Decors?</Accordion.Header>
                            <Accordion.Body>
                                <p>The packages that we framed for you are absolutely worth for completing the end to end interiors at fixed budget, variety of options with exclusive offers.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>Does Cityscape Decors provide warranty for Hardware fittings and cabinets?</Accordion.Header>
                            <Accordion.Body>
                                <p>Yes, Cityscape Decors provides 10 years warranty on Hardware fittings and Cabinets. You can get in touch with us to know more about the terms and conditions under Which the warranty will be covered.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header>Do you have a own manufacturing unit?</Accordion.Header>
                            <Accordion.Body>
                                <p>Yes, we have our own manufacturing unit in Chennai.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="6">
                            <Accordion.Header>Do you undertake only home interiors projects?</Accordion.Header>
                            <Accordion.Body>
                                <p>No, we undertake all kind of interior projects under the two major categories i.e Residential and Commercial.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="7">
                            <Accordion.Header>Do you  provide renovation services also?</Accordion.Header>
                            <Accordion.Body>
                                <p>Yes, we underatake civil related works such as Masonry works, Electrical and plumbing works along with Waterproofing works.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </Container>
        </section>
        <Modal size="lg" aria-labelledby="Youtube Video One" centered className='YtubesPop' show={YtubeOne} onHide={() => setOneShow(false)}>
            <Modal.Body>
                <Button onClick={handleOneClose} className='closebtn'>X</Button>
                <iframe src="https://www.youtube.com/embed/1CeP5v_7oEE" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </Modal.Body>
        </Modal>
        <Modal size="lg" aria-labelledby="Youtube Video Two" centered className='YtubesPop' show={YtubeTwo}  onHide={() => setTwoShow(false)}>
            <Modal.Body>
                <Button onClick={handleTwoClose} className='closebtn'>X</Button>
                <iframe src="https://www.youtube.com/embed/-vVkgUy2ZTA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </Modal.Body>
        </Modal>
        <Modal size="lg" aria-labelledby="Youtube Video Three" centered className='YtubesPop' show={YtubeThree}  onHide={() => setThreeShow(false)}>
            <Modal.Body>
                <Button onClick={handleThreeClose} className='closebtn'>X</Button>
                <iframe src="https://www.youtube.com/embed/jAgo3AjDf-o" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </Modal.Body>
        </Modal>
        <Modal size="lg" aria-labelledby="Youtube Video Four" centered className='YtubesPop' show={YtubeFour}  onHide={() => setFourShow(false)}>
            <Modal.Body>
                <Button onClick={handleFourClose} className='closebtn'>X</Button>
                <iframe src="https://www.youtube.com/embed/CTez3wcgUVY" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </Modal.Body>
        </Modal>
        <Modal size="lg" aria-labelledby="Youtube Video Five" centered className='YtubesPop' show={YtubeFive}  onHide={() => setFiveShow(false)}>
            <Modal.Body>
                <Button onClick={handleFiveClose} className='closebtn'>X</Button>
                <iframe src="https://www.youtube.com/embed/SEOglmmrmf4" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </Modal.Body>
        </Modal>
        <Modal size="lg" aria-labelledby="Youtube Video Six" centered className='YtubesPop' show={YtubeSix}  onHide={() => setSixShow(false)}>
            <Modal.Body>
                <Button onClick={handleSixClose} className='closebtn'>X</Button>
                <iframe src="https://www.youtube.com/embed/0Z20U95uUb0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </Modal.Body>
        </Modal>
        <Modal size="lg" aria-labelledby="Youtube Video Seven" centered className='YtubesPop' show={YtubeSeven}  onHide={() => setSevenShow(false)}>
            <Modal.Body>
                <Button onClick={handleSevenClose} className='closebtn'>X</Button>
                <iframe src="https://www.youtube.com/embed/-kaP1Gpu4Ag" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </Modal.Body>
        </Modal>
        <Modal size="lg" aria-labelledby="Youtube Video Eight" centered className='YtubesPop' show={YtubeEight}  onHide={() => setEightShow(false)}>
            <Modal.Body>
                <Button onClick={handleEightClose} className='closebtn'>X</Button>
                <iframe src="https://www.youtube.com/embed/8ImNoDvz_9E" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </Modal.Body>
        </Modal>

        <Modal size="lg" aria-labelledby="Find the Right Plan" centered className='RPlanPop' show={TwoBHKOne}  onHide={() => setTwoBHKOneShow(false)}>
            <Modal.Body>
                <Button onClick={handleTwoBHKOneClose} className='closebtn'>X</Button>
                    <Row>
                        <Col md="6">
                            <div className='PLnCBody'>
                                <h3>2BHK - BASIC - 3.5 LAKHS </h3>
                                <ul>
                                    <li>Tv Unit (Suitable For 49 Inch Tv)</li>
                                    <li>Shoe Cabinet (3 Ft * 3 Ft)</li>
                                    <li>Crockery (Or) Puja Unit-1 (6 Ft * 3 Ft)</li>
                                    <li>Hinged Wardrobe – A (7 Ft * 5 Ft)</li>
                                    <li>Hinged Wardrobe – B (7 Ft * 5 Ft)</li>
                                    <li>Dress Unit - 1 (5 Ft * 2 Ft)</li>
                                    <li>King Size Cot - 1 (6 Ft * 6.5 Ft)</li>
                                    <li>Kitchen Accessories (Soft Close)
                                        <ul>
                                            <li>Ss Cutlery Tray</li>
                                            <li>Ss Plain Tray</li>
                                            <li>Ss Thali Basket</li>
                                            <li>Dustbin Holder</li>
                                        </ul>
                                    </li>
                                    <li>Queen Size Cot - 1 (5 Ft * 6 Ft)</li>
                                    <li>Bed Side Tables – 4 (1.5 Ft * 1.25 Ft)</li>
                                    <li>Kitchen Bottom Cabinet -1(10 Ft * 2.75 Ft)</li>
                                    <li>Kitchen Wall Cabinet-1 (6 Ft * 2 Ft)</li>
                                </ul>
                            </div>
                        </Col>
                        <Col md="6">
                            <div className='PLnCBody'>
                                <h3>SPECIFICATIONS </h3>
                                <ul>
                                    <li>Matte Finish Laminates.</li>
                                    <li>Selective Colors Only.</li>
                                    <li>2mm Edgeband Finish For Shutters.</li>
                                    <li>Bwp Plywood For Kitchen.</li>
                                    <li>Hardwares From Ebco Brand.</li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
            </Modal.Body>
        </Modal>
        <Modal size="lg" aria-labelledby="Find the Right Plan" centered className='RPlanPop' show={TwoBHKTwo}  onHide={() => setTwoBHKTwoShow(false)}>
            <Modal.Body>
                <Button onClick={handleTwoBHKTwoClose} className='closebtn'>X</Button>
                    <Row>
                        <Col md="6">
                            <div className='PLnCBody'>
                                <h3>2BHK - PRIME - 4.5 LAKHS</h3>
                                <ul>
                                    <li>Tv Unit (Suitable For 55 Inch Tv)</li>
                                    <li>Shoe Cabinet (3 Ft * 3 Ft)</li>
                                    <li>Crockery (Or) Puja Unit-1 (6 Ft * 3 Ft)</li>
                                    <li>Hinged Wardrobe – A (7 Ft * 6 Ft)</li>
                                    <li>Hinged Wardrobe – B (7 Ft * 6 Ft)</li>
                                    <li>Dress Unit - 1 (5 Ft * 2 Ft)</li>
                                    <li>King Size Cot - 1 (6 Ft * 6.5 Ft)</li>
                                    <li>Kitchen Accessories (Soft Close)
                                        <ul>
                                            <li>Ss Cutlery Tray</li>
                                            <li>Ss Plain Tray</li>
                                            <li>Ss Thali Basket</li>
                                            <li>2 Tier – Ss Oil Pullout</li>
                                            <li>Dustbin Holder</li>
                                        </ul>
                                    </li>
                                    <li>Queen Size Cot - 1 (5 Ft * 6 Ft)</li>
                                    <li>Bed Side Tables – 4 (1.5 Ft * 1.25 Ft)</li>
                                    <li>Kitchen Bottom Cabinet-1(10 Ft * 2.75 Ft)</li>
                                    <li>Kitchen Wall Cabinet-1(6 Ft * 2 Ft)</li>
                                    <li>Gypsum False Ceiling With Electrical Works In Living Room And Master Bedroom – 280 Sq.Ft</li>
                                </ul>
                            </div>
                        </Col>
                        <Col md="6">
                            <div className='PLnCBody'>
                                <h3>SPECIFICATIONS </h3>
                                <ul>
                                    <li>Glossy Finish Laminates.</li>
                                    <li>Variety Of Color Options.</li>
                                    <li>2mm Edgeband Finish For Shutters.</li>
                                    <li>Bwp Plywood For Kitchen.</li>
                                    <li>Hardwares From Ebco Brand.</li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
            </Modal.Body>
        </Modal>        
        <Modal size="lg" aria-labelledby="Find the Right Plan" centered className='RPlanPop' show={TwoBHKThree}  onHide={() => setTwoBHKThreeShow(false)}>
            <Modal.Body>
                <Button onClick={handleTwoBHKThreeClose} className='closebtn'>X</Button>
                    <Row>
                        <Col md="6">
                            <div className='PLnCBody'>
                                <h3>2BHK - LUXE - 5.25 LAKHS</h3>
                                <ul>
                                    <li>Tv Unit (Suitable For 65 Inch Tv)</li>
                                    <li>Shoe Cabinet (3 Ft * 3 Ft)</li>
                                    <li>Crockery (Or) Puja Unit-1 (6 Ft * 3 Ft)</li>
                                    <li>Sliding Wardrobe – 1 (6 Ft * 7 Ft)</li>
                                    <li>Hinged Wardrobe – 1 (6 Ft * 7 Ft)</li>
                                    <li>Dress Unit - 1 (5 Ft * 2 Ft)</li>
                                    <li>King Size Cot - 1 (6 Ft * 6.5 Ft)</li>
                                    <li>Kitchen Accessories (Soft Close)
                                        <ul>
                                            <li>Pvc Cutlery With Tandem Drawer</li>
                                            <li>Ss Plain Tray</li>
                                            <li>Ss Thali Basket</li>
                                            <li>2 Tier-Ss Oil Pullout</li>
                                            <li>Dustbin Holder</li>
                                        </ul>
                                    </li>
                                    <li>Queen Size Cot - 1 (5 Ft * 6 Ft)</li>
                                    <li>Bed Side Tables – 4 (1.5 Ft * 1.25 Ft)</li>
                                    <li>Kitchen Bottom Cabinet-1 (10 Ft * 2.75 Ft)</li>
                                    <li>Kitchen Wall Cabinet-1 (6 Ft * 2 Ft)</li>
                                    <li>Gypsum False Ceiling With Electrical Works In Living Room And Master Bedroom – 280 Sq.Ft</li>
                                </ul>
                            </div>
                        </Col>
                        <Col md="6">
                            <div className='PLnCBody'>
                                <h3>SPECIFICATIONS </h3>
                                <ul>
                                    <li>Glossy Finish Laminates.</li>
                                    <li>Variety Of Color Options.</li>
                                    <li>2mm Edgeband Finish For Shutters.</li>
                                    <li>Bwp Plywood For Kitchen.</li>
                                    <li>Hardwares From Hettich Brand.</li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
            </Modal.Body>
        </Modal>
        <Modal size="lg" aria-labelledby="Find the Right Plan" centered className='RPlanPop' show={ThreeBHKOne}  onHide={() => setThreeBHKOneShow(false)}>
            <Modal.Body>
                <Button onClick={handleThreeBHKOneClose} className='closebtn'>X</Button>
                    <Row>
                        <Col md="6">
                            <div className='PLnCBody'>
                                <h3>3BHK - BASIC - 4.85 LAKHS</h3>
                                <ul>
                                    <li>Tv Unit (Suitable For 49 Inch Tv)</li>
                                    <li>Shoe Cabinet (4 Ft * 3 Ft)</li>
                                    <li>Crockery (Or) Puja Unit-1 (6 Ft * 3.5 Ft)</li>
                                    <li>Hinged Wardrobe – 3 (7 Ft * 6 Ft)</li>
                                    <li>Kitchen Accessories (Soft Close)
                                        <ul>
                                            <li>Ss Cutlery Tray</li>
                                            <li>Ss Plain Tray</li>
                                            <li>Ss Thali Basket</li>
                                            <li>Ss Oil Pullout (2 Tier)</li>
                                            <li>Dustbin Holder</li>
                                        </ul>
                                    </li>
                                    <li>Dress Unit - A (5 Ft * 2 Ft)</li>
                                    <li>Dress Unit – B (5 Ft * 2 Ft)</li>
                                    <li>King Size Cot - 2 (6 Ft * 6.5 Ft)</li>
                                    <li>Queen Size Cot - 1 (5 Ft * 6 Ft)</li>
                                    <li>Bed Side Tables – 6 (1.5 Ft * 1.25 Ft)</li>
                                    <li>Kitchen Bottom Cabinet -1(12 Ft * 2.75 Ft)</li>
                                    <li>Kitchen Wall Cabinet-1 (8 Ft * 2 Ft)</li>
                                </ul>
                            </div>
                        </Col>
                        <Col md="6">
                            <div className='PLnCBody'>
                            <h3>SPECIFICATIONS </h3>
                            <ul>
                                <li>Matte Finish Laminates.</li>
                                <li>Selective Colors Only.</li>
                                <li>Bwp Plywood For Kitchen.</li>
                                <li>Hardwares From Ebco Brand.</li>
                            </ul>
                            </div>
                        </Col>
                    </Row>
            </Modal.Body>
        </Modal>
        <Modal size="lg" aria-labelledby="Find the Right Plan" centered className='RPlanPop' show={ThreeBHKTwo}  onHide={() => setThreeBHKTwoShow(false)}>
            <Modal.Body>
                <Button onClick={handleThreeBHKTwoClose} className='closebtn'>X</Button>
                    <Row>
                        <Col md="6">
                            <div className='PLnCBody'>
                                <h3>3BHK - PRIME - 5.5 LAKHS</h3>
                                <ul>
                                    <li>Tv Unit (Suitable For 55 Inch Tv)</li>
                                    <li>Shoe Cabinet (4 Ft * 3 Ft)</li>
                                    <li>Crockery (Or) Puja Unit (6 Ft * 3.5 Ft)</li>
                                    <li>Hinged Wardrobe – 3 (7 Ft * 7 Ft)</li>
                                    <li>Kitchen Accessories
                                        <ul>
                                            <li>Ss Cutlery Tray</li>
                                            <li>Ss Plain Tray</li>
                                            <li>6 Inch Tandem Drawer – 2</li>
                                            <li>Ss Thali Basket </li>
                                            <li>2 Tier – Ss Oil Pullout</li>
                                            <li>Dustbin Holder</li>
                                        </ul>
                                    </li>
                                    <li>Dress Unit - A (5 Ft * 2 Ft)</li>
                                    <li>Dress Unit – B (5 Ft * 2 Ft)</li>
                                    <li>King Size Cot - 2 (6 Ft * 6.5 Ft)</li>
                                    <li>Queen Size Cot - 1 (5 Ft * 6 Ft)</li>
                                    <li>Bed Side Tables – 6 (1.5 Ft * 1.25 Ft)</li>
                                    <li>Kitchen Bottom Cabinet-1 (12 Ft * 2.75 Ft) </li>
                                    <li>Kitchen Wall Cabinet-1 (8 Ft * 2 Ft)</li>
                                </ul>
                            </div>
                        </Col>
                        <Col md="6">
                            <div className='PLnCBody'>
                                <h3>SPECIFICATIONS </h3>
                                <ul>
                                    <li>Glossy Finish Laminates.</li>
                                    <li>Variety Of Color Options.</li>
                                    <li>2mm Edgeband Finish For Shutters.</li>
                                    <li>Bwp Plywood For Kitchen.</li>
                                    <li>Hardwares From Ebco Brand.</li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
            </Modal.Body>
        </Modal>
        <Modal size="lg" aria-labelledby="Find the Right Plan" centered className='RPlanPop' show={ThreeBHKThree}  onHide={() => setThreeBHKThreeShow(false)}>
            <Modal.Body>
                <Button onClick={handleThreeBHKThreeClose} className='closebtn'>X</Button>
                    <Row>
                        <Col md="6">
                            <div className='PLnCBody'>
                                <h3>3BHK - LUXE - 6.5 LAKHS </h3>
                                <ul>
                                    <li>Tv Unit (Suitable For 65 Inch Tv)</li>
                                    <li>Shoe Cabinet (4 Ft * 3 Ft)</li>
                                    <li>Crockery (Or) Puja Unit-1 (6 Ft * 3.5 Ft)</li>
                                    <li>Sliding Wardrobe – 1 (7 Ft * 7 Ft)</li>
                                    <li>Hinged Wardrobe – 2 (7 Ft * 7 Ft)</li>
                                    <li>Kitchen Accessories (Hettich)
                                        <ul>
                                            <li>Pvc Cutlery With Tandem Drawer</li>
                                            <li>Tandem Drawer With Gallery Rod – 2no’s</li>
                                            <li>Ss Plain Tray</li>
                                            <li>Ss Thali Basket</li>
                                            <li>2 Tier – Ss Oil Pullout</li>
                                            <li>Dustbin Holder</li>
                                        </ul>
                                    </li>
                                    <li>Dress Unit – A (5 Ft * 2 Ft)</li>
                                    <li>Dress Unit – B (5 Ft * 2 Ft) </li>
                                    <li>King Size Cot – 2 (6 Ft * 6.5 Ft)</li>
                                    <li>Queen Size Cot - 1 (5 Ft * 6 Ft)</li>
                                    <li>Bed Side Tables – 6 (1.5 Ft * 1.25 Ft)</li>
                                    <li>Kitchen Bottom Cabinet-1 (12 Ft * 2.75 Ft)</li>
                                    <li>Kitchen Wall Cabinet-1 (8 Ft * 2 Ft)</li>
                                </ul>
                            </div>
                        </Col>
                        <Col md="6">
                            <div className='PLnCBody'>
                                <h3>SPECIFICATIONS </h3>
                                <ul>
                                    <li>Glossy Finish Laminates.</li>
                                    <li>Variety Of Color Options.</li>
                                    <li>2mm Edgeband Finish For Shutters.</li>
                                    <li>Bwp Plywood For Kitchen.</li>
                                    <li>Hardwares From Hettich Brand.</li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
            </Modal.Body>
        </Modal>
    </>
  )
}

export default Home