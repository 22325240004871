import React, { Component } from "react";
import Slider from "react-slick";

export default class Ourservices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }

  render() {
    return (
      <div className="OSSliders">
        <div className="OSImgSlide" >
        <Slider className="OSISlide"
          asNavFor={this.state.nav2}
          ref={slider => (this.slider1 = slider)}
          fade={true}
          responsive = {[
            {breakpoint: 550,
                settings: {
                    slidesToShow: 2,
                }
            }
        ] }
        >
            <div><div className="OSISlideImgs"> <img src={require ('../../asstes/images/OS-fales-ceiling.jpg')} alt=""/> <h3>False Ceiling</h3></div></div>
            <div><div className="OSISlideImgs"> <img src={require ('../../asstes/images/OS-wall-paper.jpg')} alt=""/> <h3>Wall Paper</h3></div></div>
            <div><div className="OSISlideImgs"> <img src={require ('../../asstes/images/OSerImg.jpg')} alt=""/> <h3>Wall Painting</h3></div></div>
            <div><div className="OSISlideImgs"> <img src={require ('../../asstes/images/OS-tile-laying.jpg')} alt=""/> <h3>Tile Laying</h3></div></div>
            <div><div className="OSISlideImgs"> <img src={require ('../../asstes/images/OS-wooden-flooring.jpg')} alt=""/> <h3>Wooden Flooring</h3></div></div>
            <div><div className="OSISlideImgs"> <img src={require ('../../asstes/images/OS-landscape.jpg')} alt=""/> <h3>Landscape</h3></div></div>
            <div><div className="OSISlideImgs"> <img src={require ('../../asstes/images/OS-customized-furniture.jpg')} alt=""/> <h3>Customised Furniture</h3></div></div>
            <div><div className="OSISlideImgs"> <img src={require ('../../asstes/images/OS-exposed-brick-laying.jpg')} alt=""/> <h3>Exposed Bricks Laying</h3></div></div>
            <div><div className="OSISlideImgs"> <img src={require ('../../asstes/images/OS-designed-tile-laying.jpg')} alt=""/> <h3>Designer Tile Laying</h3></div></div>
            <div><div className="OSISlideImgs"> <img src={require ('../../asstes/images/OS-texture-painting.jpg')} alt=""/> <h3>Texture Painting</h3></div></div>
            <div><div className="OSISlideImgs"> <img src={require ('../../asstes/images/OS-water-proofing.jpg')} alt=""/> <h3>Water Proofing</h3></div></div>
            <div><div className="OSISlideImgs"> <img src={require ('../../asstes/images/OS-design-consultant.jpg')} alt=""/> <h3>Design Consultant</h3></div></div>
            <div><div className="OSISlideImgs"> <img src={require ('../../asstes/images/OS-turnkey-contracting.jpg')} alt=""/> <h3>Turnkey Contracting</h3></div></div>
        </Slider>
        </div>
        <div className="OSContSlide" >
        <Slider className="OSCSlide"
          asNavFor={this.state.nav1}
          ref={slider => (this.slider2 = slider)}
          slidesToShow={13}
          infinite={true}
          swipeToSlide={true}
          focusOnSelect={true}
          vertical={true}
          autoplay={true}
          autoplaySpeed={5000}
        >
            <div><h3>False Ceiling</h3></div>
            <div><h3>Wall Paper</h3></div>
            <div><h3>Wall Painting</h3></div>
            <div><h3>Tile Laying</h3></div>
            <div><h3>Wooden Flooring</h3></div>
            <div><h3>Landscape</h3></div>
            <div><h3>Customised Furniture</h3></div>
            <div><h3>Exposed Bricks Laying</h3></div>
            <div><h3>Designer Tile Laying</h3></div>
            <div><h3>Texture Painting</h3></div>
            <div><h3>Water Proofing</h3></div>
            <div><h3>Design Consultant</h3></div>
            <div><h3>Turnkey Contracting</h3></div>
        </Slider>
        </div>
      </div>
    );
  }
}