import React, { Component } from "react";
import Slider from "react-slick";

export default class Testimonialslide extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }

  render() {
    return (
      <div className="TestmoSliders">
        
        <div className="TestmoImgSlide" >
            <Slider className="TestmoISlide"
            asNavFor={this.state.nav1}
            ref={slider => (this.slider2 = slider)}
            slidesToShow={3}
            infinite={true}
            swipeToSlide={true}
            arrows={false}
            focusOnSelect={true}
            centerMode={true}
            autoplay={true}
            autoplaySpeed={5000}
            >
                <div><div className="TestmoImg"><img src={require ('../../asstes/images/TestmoImg0.jpg')} alt=""/></div></div>
                <div><div className="TestmoImg"><img src={require ('../../asstes/images/TestmoImg1.jpg')} alt=""/></div></div>
                <div><div className="TestmoImg"><img src={require ('../../asstes/images/TestmoImg2.jpg')} alt=""/></div></div>
                <div><div className="TestmoImg"><img src={require ('../../asstes/images/TestmoImg0.jpg')} alt=""/></div></div>
                <div><div className="TestmoImg"><img src={require ('../../asstes/images/TestmoImg1.jpg')} alt=""/></div></div>
                <div><div className="TestmoImg"><img src={require ('../../asstes/images/TestmoImg2.jpg')} alt=""/></div></div>
            </Slider>
        </div>
        <div className="TestmoContSlide" >
            <Slider className="TestmoCSlide"
            asNavFor={this.state.nav2}
            ref={slider => (this.slider1 = slider)}
            fade={true}
            >
              <div>
                  <div className="TestmoCont">
                      <h3>Mr. Lokesh</h3>
                      <h5>HDFC Bank Manager</h5>
                      <h4>It was a delightful experience to get changes made to our home from Cityscape Decors. The Customised sofa and other home accessories were of better quality than we imagined them to be. Mr.Shiva ,our designated designer made the entire experience extremely smooth with his patience and made perfect suggestions as per our requirement.</h4>
                  </div>
              </div>
              <div>
                  <div className="TestmoCont">
                      <h3>MR. Ravi</h3>
                      <h5>Qatar</h5>
                      <h4>Excellent support provided by the team. They spend lot of time in<br/> planning and identifying a suitable design for you.<br/> We found them very professional and customer centric.<br/> Wish you Good luck !</h4>
                  </div>
              </div>
              <div>
                  <div className="TestmoCont">
                      <h3>Mrs. Vani Subramanyam</h3>
                      <h5>Coimbatore</h5>
                      <h4>Saravanan, Shiva and their interior designer Megha took note of all my requirements and included them with a practical touch. I must acknowledge Saravanan’s commitment,  Shiva’s dedication & personalised attention to detail thus completing my flat under difficult circumstances. My family appreciate their efforts and recommend them to all our near & dear ones.</h4>
                  </div>
              </div>
              <div>
                  <div className="TestmoCont">
                      <h3>Mr. Lokesh</h3>
                      <h5>HDFC Bank Manager</h5>
                      <h4>It was a delightful experience to get changes made to our home from Cityscape Decors. The Customised sofa and other home accessories were of better quality than we imagined them to be. Mr.Shiva ,our designated designer made the entire experience extremely smooth with his patience and made perfect suggestions as per our requirement.</h4>
                  </div>
              </div>
              <div>
                  <div className="TestmoCont">
                      <h3>MR. Ravi</h3>
                      <h5>Qatar</h5>
                      <h4>Excellent support provided by the team. They spend lot of time in<br/> planning and identifying a suitable design for you.<br/> We found them very professional and customer centric.<br/> Wish you Good luck !</h4>
                  </div>
              </div>
              <div>
                  <div className="TestmoCont">
                      <h3>Mrs. Vani Subramanyam</h3>
                      <h5>Coimbatore</h5>
                      <h4>Saravanan, Shiva and their interior designer Megha took note of all my requirements and included them with a practical touch. I must acknowledge Saravanan’s commitment,  Shiva’s dedication & personalised attention to detail thus completing my flat under difficult circumstances. My family appreciate their efforts and recommend them to all our near & dear ones.</h4>
                  </div>
              </div>
            </Slider>
        </div>
      </div>
    );
  }
}