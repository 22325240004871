import React from 'react';
import { useState, useEffect } from 'react';
import './header.css';
import {NavLink, Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Consultationpopup from '../../Pages/HComponents/Consultationpopup';
import { NavHashLink  } from 'react-router-hash-link';
const Header = () => {

    const scrollDirection = useScrollDirection();
    const [scroll, setScroll] = useState(false)
    useEffect(() => {

        if (window.matchMedia("(min-width: 801px)").matches) {
            window.addEventListener("scroll", () => {
                setScroll(window.scrollY > 10)
            })
        }
    }, []);

    function useScrollDirection() {
        const [scrollDirection, setScrollDirection] = useState(null);
    
        useEffect(() => {
            let lastScrollY = window.pageYOffset;            
            if (window.matchMedia("(min-width: 801px)").matches) {
                // function to run on scroll
                const updateScrollDirection = () => {
                    const scrollY = window.pageYOffset;
                    const direction = scrollY > lastScrollY ? "down" : "up";
                    if (direction !== scrollDirection) {
                    setScrollDirection(direction);
                    }
                    lastScrollY = scrollY > 0 ? scrollY : 0;
                };
                window.addEventListener("scroll", updateScrollDirection); // add event listener
                return () => {
                    window.removeEventListener("scroll", updateScrollDirection); // clean up
                }
            }
        }, [scrollDirection]); // run when scroll direction changes
            
        return scrollDirection;
    };

    const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
        <header className={`CDHeader ${ scrollDirection === "down" ? "down" : "up"} ${scroll ? "scrolled" : ""}`}>
            <div className='headspilt'>
                <div className='HLogoD'><NavHashLink to={`${process.env.PUBLIC_URL}/#BanSec`} className="nav__link"><img src={require('../../asstes/images/CDLogo.png')} alt="City Scape Decors"/></NavHashLink></div>
                <div className='HMenuD'>
                    <nav className="nav">
                        <ul className="cdmainMenu">
                            <li className="nav__item"><NavHashLink exact="true" to={`${process.env.PUBLIC_URL}/#BanSec`}  activeclassname="selected">Home</NavHashLink></li>
                            <li className="nav__item"><NavHashLink to={`${process.env.PUBLIC_URL}/#Aboutus`}  activeclassname="selected">About</NavHashLink></li>
                            <li className="nav__item"><NavHashLink to={`${process.env.PUBLIC_URL}/#OServices`}  activeclassname="selected">Our Services</NavHashLink></li>
                            <li className="nav__item"><NavHashLink to={`${process.env.PUBLIC_URL}/#OPackages`}  activeclassname="selected">Our Packages</NavHashLink></li>
                            <li className="nav__item"><NavHashLink to={`${process.env.PUBLIC_URL}/#OLProject`}  activeclassname="selected">Our Latest Projects</NavHashLink></li>
                            <li className="nav__item lstalkbtn"><NavLink exact="true" to='#' className="nav__link" onClick={() => setModalShow(true)}>Get Free Quote</NavLink></li>
                        </ul>
                    </nav>
                </div>
                <div className='HMenuEN'>
                    <Button onClick={() => setModalShow(true)}><img src={require ('../../asstes/images/HEnquireIcon.png')} alt=""/></Button>                    
                    <Consultationpopup show={modalShow} onHide={() => setModalShow(false)}/>
                </div>
            </div>
        </header>
        <div id="mobile-nav-sticky" className="mobile-nav-sticky">
            <ul className="mns-list">
                <li><Link to="tel:+919677044222"><div className="mnv-icon-view mnv-icon-call"></div><strong>Call Us</strong></Link></li>	
                <li><Link to="#" className="mnv-enquires" onClick={() => setModalShow(true)}><div className="mnv-icon-view mnv-icon-enquire"></div><strong>Enquiry</strong></Link></li>	
                <li className="mnv-logo"><NavHashLink to={`${process.env.PUBLIC_URL}/#BanSec`}><div className="mnv-logo-img"></div></NavHashLink></li>	
                <li><NavHashLink to={`${process.env.PUBLIC_URL}/#OLProject`} className="mnv-icon-gallery"><div className="mnv-icon-view mnv-icon-gallery"></div><strong>Gallery</strong></NavHashLink></li>	
                <li><Link to="https://api.whatsapp.com/send?phone=919677044222&text=" className="mnv-icon-wtsapp" target='_blank'><div className="mnv-icon-view mnv-icon-wtsapp"></div><strong>Whatsapp</strong></Link></li>	
            </ul>
        </div>
    </>
  )
}

export default Header