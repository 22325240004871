// src/gtag.js
export const GA_MEASUREMENT_ID = 'G-PBX7B4QYHV';

export const initGA = () => {
  if (process.env.NODE_ENV === 'production') {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', GA_MEASUREMENT_ID);
  }
};
